<template>
    <div class="app-container">
        <div class="filter-container">
            <el-button class="filter-item" type="primary" size="small" @click="handleCreate">新增</el-button>
        </div>

        <el-table v-loading="listLoading" :data="list" element-loading-text="Loading" fit highlight-current-row>
            <el-table-column align="center" label="ID" width="80">
                <template slot-scope="scope">
                    {{ scope.row.id }}
                </template>
            </el-table-column>

            <el-table-column label="定时轮播图（图片 / ID）" min-width="600" align="center">
                <template slot-scope="scope">
                    <div style="display: flex;align-items: center;justify-content: center;flex-wrap: wrap;">
                        <div v-for="(item, index) in scope.row.banner" :key="index" style="margin: 5px;display: flex;flex-direction:column;align-items:center;text-align:center;">
                            <img :src="item.img" class="user-avatar" style="border: 1px solid #e6e6e6;">
                            <span>{{ item.id }}</span>
                        </div>
                    </div>
                </template>
            </el-table-column>

            <el-table-column label="上架时间" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.upper_shelf_time }}
                </template>
            </el-table-column>

            <el-table-column label="下架时间" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.lower_shelf_time }}
                </template>
            </el-table-column>

            <el-table-column label="操作" fixed="right" align="center" width="200" class-name="small-padding fixed-width">
                <template slot-scope="scope">
                    <el-button type="primary" size="mini" @click="handleUpdate(scope.row)">编辑</el-button>

                    <el-button type="danger" size="mini" @click="handleDel(scope.row.id)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>

        <div class="pagination-container">
            <el-pagination :current-page="listQuery.page" :page-sizes="[5,10,20,30, 50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
        </div>

        <!-- 详情 -->
        <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible" width="50%" :close-on-click-modal="false">
            <el-form ref="form" :rules="rules" :model="form" label-position="left" label-width="150px">
                <el-form-item label="定时轮播图" prop="banner_ids">
                    <el-checkbox-group v-model="form.banner_ids">
                        <el-checkbox v-for="item in bannerList" :key="item.id" :label="item.id">
                            <div style="display: flex;flex-direction:column;align-items:center;text-align:center;">
                                <img :src="item.img" class="user-avatar" style="border: 1px solid #e6e6e6;">
                                <span>{{ item.id }}</span>
                            </div>
                        </el-checkbox>
                    </el-checkbox-group>
                </el-form-item>

                <el-form-item label="上架时间" prop="upper_shelf_time">
                    <el-time-picker v-model="form.upper_shelf_time" format="HH:mm" value-format="HH:mm" placeholder="选择时间"></el-time-picker>
                </el-form-item>

                <el-form-item label="下架时间" prop="lower_shelf_time">
                    <el-time-picker v-model="form.lower_shelf_time" format="HH:mm" value-format="HH:mm" placeholder="选择时间"></el-time-picker>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取消</el-button>
                <el-button type="primary" @click="saveData" :loading="btnLoading">确定</el-button>
            </div>
        </el-dialog>
        <!-- 详情 -->
    </div>
</template>

<script>
    import request from "@/utils/request";
    import { mapGetters } from "vuex";

    export default {
        data() {
            return {
                list: null,
                total: null,
                listLoading: true,
                listQuery: {
                    page: 1,
                    limit: 10,
                    school_id: '',
                },
                dialogFormVisible: false,
                dialogStatus: '',
                textMap: {
                    update: '修改',
                    create: '新增',
                },
                btnLoading: false,
                form:{
                    id: '',
                    school_id: '',
                    banner_ids: [],
                    upper_shelf_time: '',
                    lower_shelf_time: '',
                },
                rules: {
                    banner_ids: [{ required: true, message: '请选择定时轮播图', trigger: 'change' }],
                    upper_shelf_time: [{ required: true, message: '请选择上架时间', trigger: 'change' }],
                    lower_shelf_time: [{ required: true, message: '请选择下架时间', trigger: 'change' }],
                },
                bannerList: [],
            };
        },
        created() {
            this.getList();
            this.getBannerList();
        },
        computed: {
            ...mapGetters(["schools", "school_id","user"]),
            // 过滤已失效id
            bannerSelectedFilter: function() {
                let ids = []
                for(let i=0;i<this.form.banner_ids.length;i++){
                    if (this.bannerList.find((item) => item.id === this.form.banner_ids[i])) {
                        ids.push(this.form.banner_ids[i]);
                    }
                }
                return ids;
            },
        },
        watch: {
            school_id(newVal, oldVal) {
                this.listQuery.school_id = newVal;
                this.getList();
                this.getBannerList();
            },
        },
        methods: {
            getList() {
                this.listQuery.school_id = this.school_id;
                this.listLoading = true;
                request({
                    url: "/api/backend/bannerTimingDisplay/list",
                    method: "get",
                    params: this.listQuery
                }).then(response => {
                    this.list = response.data.data;
                    this.total = response.data.total;
                    this.listLoading = false;
                });
            },
            getBannerList() {
                request({
                    url: "/api/backend/banner/list",
                    method: "get",
                    params: {
                        page: 1,
                        limit: 100000,
                        school_id: this.school_id
                    }
                }).then(response => {
                    this.bannerList = response.data.data;
                });
            },
            handleFilter() {
                this.listQuery.page = 1;
                this.getList();
            },
            handleSizeChange(val) {
                this.listQuery.limit = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.listQuery.page = val;
                this.getList();
            },
            resetForm() {
                this.form = {
                    id: '',
                    school_id: '',
                    banner_ids: [],
                    upper_shelf_time: '',
                    lower_shelf_time: '',
                }
                this.btnLoading = false
            },
            handleCreate() {
                this.resetForm()
                this.dialogStatus = 'create'
                this.dialogFormVisible = true
                this.$nextTick(() => {
                    this.$refs['form'].clearValidate() //清除校验结果
                })
            },
            handleUpdate(row) {
                this.resetForm()
                this.form = Object.assign({}, row)
                this.form.banner_ids = this.bannerSelectedFilter;
                this.dialogStatus = 'update'
                this.dialogFormVisible = true
                this.$nextTick(() => {
                    this.$refs['form'].clearValidate() //清除校验结果
                })
            },
            saveData() {
                if (!this.school_id) {
                    this.$message({
                        type: "warning",
                        message: "请选择学校"
                    });
                    return;
                } else {
                    this.form.school_id = this.school_id;
                }

                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        this.btnLoading = true
                        request({
                            url: '/api/backend/bannerTimingDisplay/save',
                            method: 'post',
                            data: this.form
                        }).then(() => {
                            this.btnLoading = false
                            this.dialogFormVisible = false
                            this.$message({
                                type: 'success',
                                message: '操作成功'
                            });
                            this.getList();
                        }).catch(() => {
                            this.btnLoading = false
                        })
                    }
                })
            },
            handleDel(val) {
                this.$confirm('确认继续该操作？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    request({
                        url: '/api/backend/bannerTimingDisplay/del',
                        method: 'post',
                        data: {id: val}
                    }).then(()=>{
                        this.getList()
                        this.$message({
                            type: 'success',
                            message: '操作成功'
                        });
                    })
                }).catch(() => {

                });
            },
        }
    };
</script>


<style rel="stylesheet/scss" lang="scss" scoped>
    .user-avatar {
        width: 40px;
        height: 40px;
        border-radius: 6px;
    }
</style>
